var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('span',{staticClass:"text-xl",domProps:{"innerHTML":_vm._s(_vm.$t('clear_users_set_interval'))}}),_c('a-divider')],1),_c('div',[_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('clear_users_from_title'))+" ")]),_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('date-picker-input',{attrs:{"setup":{
            'func': _vm.inputSetup,
            'args': {
              'model': _vm.modelCopy.from,
              'key': 'date',
              'prefix': 'clear_users_from_'
            }
          }}}),_c('time-picker-input',{attrs:{"setup":{
            'func': _vm.inputSetup,
            'args': {
              'model': _vm.modelCopy.from,
              'key': 'time',
              'prefix': 'clear_users_from_',
              'time24hr': true
            }
          }}})],1)]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('clear_users_to_title'))+" ")]),_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('date-picker-input',{attrs:{"setup":{
            'func': _vm.inputSetup,
            'args': {
              'model': _vm.modelCopy.to,
              'key': 'date',
              'prefix': 'clear_users_to_'
            }
          }}}),_c('time-picker-input',{attrs:{"setup":{
            'func': _vm.inputSetup,
            'args': {
              'model': _vm.modelCopy.to,
              'key': 'time',
              'prefix': 'clear_users_to_',
              'time24hr': true
            }
          }}})],1)]),_c('a-divider'),_c('div',{staticClass:"text-right"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.setPeriod}},[_vm._v(" Установить период ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }