var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"transfer-license-wrapper"},[(_vm.isMounted)?_c('div',{staticClass:"transfer-license"},[_c('a-alert',{attrs:{"message":_vm.$t('transfer_license_info_message')}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'target_id',
            'options': _vm.getChatOptions,
            'clearable': false,
            'validation': 'required',
          }
        }}}),(_vm.showAlert)?_c('div',{staticClass:"mb-4"},[_c('a-alert',{staticClass:"h-full mb-4",attrs:{"type":"error","message":_vm.$t('transfer_license_choice_license')}}),_c('div',{staticClass:"w-full flex justify-around"},_vm._l((_vm.chatsTariffs),function(tariff,index){return _c('div',{key:index,staticClass:"flex hover:cursor-pointer w-1/3 px-4 py-2 rounded-lg",style:({
              backgroundColor: _vm.currentTariff === tariff ? Object.entries(_vm.$store.state.themeConfig.config.tariffs)[tariff][1].color : '',
              border: '1px solid',
              borderColor: Object.entries(_vm.$store.state.themeConfig.config.tariffs)[tariff][1].color
            }),on:{"click":function($event){_vm.currentTariff = _vm.chatsTariffs[index]}}},[_c('img',{staticClass:"mr-4",staticStyle:{"width":"50px"},attrs:{"src":Object.entries(_vm.$store.state.themeConfig.config.tariffs)[tariff][1].img}}),_c('div',{staticClass:"h-full flex items-center"},[_c('span',{staticClass:"font-bold text-lg",class:[_vm.currentTariff === tariff ? 'text-white' : 'text-primary']},[_vm._v(" "+_vm._s(Object.entries(_vm.$store.state.themeConfig.config.tariffs)[tariff][0])+" ")])])])}),0)],1):_vm._e(),_c('div',{staticClass:"flex flex-col md:flex-row md:justify-end"},[_c('a-button',{staticClass:"btn-wrap ml-2",attrs:{"type":"","disabled":!_vm.target_id},on:{"click":_vm.transferLicense}},[_vm._v(" "+_vm._s(_vm.$t('transfer_license_button_transfer'))+" ")]),_c('a-button',{staticClass:"btn-wrap mt-2 md:mt-0 ml-2",attrs:{"type":"danger"},on:{"click":function($event){return _vm.$emit('transfer')}}},[_vm._v(" "+_vm._s(_vm.$t('transfer_license_button_cancel'))+" ")])],1)],1):_c('a-spin',{staticClass:"w-full text-center"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }