














































































import PageTitle from "@/components/PageTitle.vue";
import Api from "@/includes/logic/Api";
import { InputSetups } from '@/mixins/input-setups'
import SetInterval from "./components/setInterval.vue"
import RemoveUsersRequest from "./components/RemoveUsersRequest.vue";
import { Interval } from "@/views/chat/removeForPeriod/types";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import Tags from "piramis-base-components/src/components/Tags/Tags.vue";
import { UseFields } from "piramis-base-components/src/components/Pi";
import Card from "piramis-base-components/src/components/Pi/components/Card.vue";

import { Component, Mixins, Watch } from "vue-property-decorator";
import moment from "moment";
import { errorNotification } from '@/includes/NotificationService'

@Component({
  components: {
    RemoveUsersRequest,
    PageTitle,
    SetInterval,
    Tags,
    Card
  },
  data() {
    return {
      InputSetups,
      RemoveUsersRequest,
      moment
    }
  }
})
export default class RemoveForPeriod extends Mixins<UseFields, TariffsTagsHelper>(UseFields, TariffsTagsHelper) {
  interval = {
    from: {
      time: '',
      date: ''
    },
    to: {
      time: '',
      date: ''
    }
  }

  step = 1

  count = null
  deleted = null
  code = null

  clearUsersEstablished = false
  process = false
  activePopup = false
  popupTemplate = 0

  loading = false
  error = false

  removeButtonLoading = false

  @Watch('loading')
  loadingWatcher(bool: boolean): void {
    if(bool) {
      this.removeButtonLoading = true
    }
  }

  @Watch('activePopup')
  popupWatcher(bool: boolean) {
    if(!bool && (!this.code || !this.count)) {
      this.interval.from.date = moment().format('YYYY-MM-DD').split('/').join('-')
      this.interval.from.time = '12:00:00'
      this.interval.to.date = moment().add('days', 1).format('YYYY-MM-DD').split('/').join('-')
      this.interval.to.time = '12:00:00'
      this.step === 1 ? null : Api.clearUsers('tg', { chat_id: this.chatId, action: 'delete', code: this.code })
      this.step = 1
      this.popupTemplate += 1
    }
  }

  get chatId(): number {
    return this.$store.state.chatState.chat.chat_id
  }

  stopRemovingUsers() {
    this.error = false
    Api.clearUsers('tg', { action: 'delete', chat_id: this.chatId, code: this.code }).then(data => {
      this.process = false
      this.clearUsersEstablished = false
      this.deleted = null
      this.interval.from.date = moment().format('YYYY-MM-DD').split('/').join('-')
      this.interval.from.time = '12:00:00'
      this.interval.to.date = moment().add('days', 1).format('YYYY-MM-DD').split('/').join('-')
      this.interval.to.time = '12:00:00'
    })
  }

  setInterval(modelCopy: Interval): void {
    let from = `${ modelCopy.from.date } ${ modelCopy.from.time }`
    let to = `${ modelCopy.to.date } ${ modelCopy.to.time }`
    if(moment(from).isAfter(to)) {
      errorNotification(this.$t('remove_for_the_period_incorrect_period').toString())
    } else if(moment(from).isBefore(moment().add(-14, 'day'))) {
      errorNotification(this.$t('remove_for_the_period_to_early', { 0: moment().add(-14, 'day').format('DD-MM-YYYY HH-mm') }).toString())
    } else {
      this.interval = { ...modelCopy }
      const intFrom = this.interval.from
      const intTo = this.interval.to
      Api.clearUsers('tg', { chat_id: this.chatId, action: 'create', from: `${ intFrom.date } ${ intFrom.time }`, to: `${ intTo.date } ${ intTo.time }` }).then(response => {
        this.count = response.data.data.count
        this.step = 2
      })
    }
  }

  createRequest(): void {
    this.clearUsersEstablished = true
    const intFrom = this.interval.from
    const intTo = this.interval.to
    Api.clearUsers('tg', {
      chat_id: this.chatId,
      action: 'create',
      from: `${ intFrom.date } ${ intFrom.time }`,
      to: `${ intTo.date } ${ intTo.time }`,
      count: this.count
    }).then(response => {
      this.code = response.data.data.code
      this.activePopup = false
      this.step = 1
    }).catch((error) => {

      errorNotification(error)
      this.error = true
    })
  }

  stepBack(): void {
    this.stopRemovingUsers()
    this.step = 1
  }

  check(): void {
    Api.clearUsers('tg', { chat_id: this.chatId, action: 'get' })
  }

  goRemove(): void {
    this.loading = true
    Api.clearUsers('tg', {
      chat_id: this.chatId,
      action: 'create',
      from: `${ this.interval.from.date } ${ this.interval.from.time }`,
      to: `${ this.interval.to.date } ${ this.interval.to.time }`,
      count: this.count,
      code: this.code
    }).then(response => {
      if(this.clearUsersEstablished) {
        this.process = true
        this.logRemove()
        this.loading = false
      }
      this.loading = false
    }).finally(() => {
      this.loading = false
    })
  }

  logRemove() {
    if(this.process) {
      setTimeout(() => {
        Api.clearUsers('tg', {
          chat_id: this.chatId,
          action: 'get'
        }).then(response => {
          if (response.data.data) {
            this.deleted = response.data.data.processed
            this.logRemove()
          }
        })
      },3000)
    }
  }

  created(): void {
    if (this.$store.getters.isChatAtLeastAdvanced && this.$store.getters.isChatLicenseExists) {
      Api.clearUsers('tg', {
        chat_id: this.chatId,
        action: 'get'
      }).then(response => {
        if(response.data.data) {
          const intFrom = response.data.data.from.split(' ')
          const intTo = response.data.data.to.split(' ')
          this.interval.from.date = intFrom[0]
          this.interval.from.time = intFrom[1]
          this.interval.to.date = intTo[0]
          this.interval.to.time = intTo[1]
          this.clearUsersEstablished = true
          this.count = response.data.data.count
          this.code = response.data.data.code
          if(response.data.data.processed !== -1) {
            this.process = true
            this.logRemove()
          }
        } else {
          this.interval.from.date = moment().format('YYYY-MM-DD').split('/').join('-')
          this.interval.from.time = '12:00:00'
          this.interval.to.date = moment().add('days', 1).format('YYYY-MM-DD').split('/').join('-')
          this.interval.to.time = '12:00:00'
        }
      })
    }
  }
}
