



































// Project
import Api from "@/includes/logic/Api";
import { InputSetups } from '../../mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/NotificationService'

import { MessageEditorWithMediaData } from "piramis-base-components/src/components/MessageEditorWithMedia/types";
import { UseFields } from "piramis-base-components/src/components/Pi";
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
})
export default class VuSendMessageExtra extends Mixins(UseFields, InputSetups) {
  model: { message: MessageEditorWithMediaData } = {
    message: {
      text                : "",
      remove_after        : 0,
      send_after          : 0,
      pin                 : false,
      disable_link_preview: false,
      disable_notify      : false,
      buttons             : [],
    }
  }

  successMessage(): void {
    successNotification()
  }

  sendSelfMessage(): void {
    Api.sendSelfMessage("tg", { 'message': [ this.model.message ] })
      .then(this.successMessage)
      .catch(errorNotification);
  }

  sendChatMessage(): void  {
    Api.sendChatMessage("tg", {
      'chat_id': parseInt(this.$route.params[EntityTypes.CHAT_ID]),
      'message': [ this.model.message ],
    })
      .then(this.successMessage)
      .catch(errorNotification);
  }
}
