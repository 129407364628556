

















































import Api from "../../includes/logic/Api";
import triggersMixin from "../../mixins/triggersMixin";
import { InputSetups } from "@/mixins/input-setups"
import CreateTriggerModalMixin from '@/components/CreateTriggerModal/CreateTriggerModalMixin'

import { UseFields } from "piramis-base-components/src/components/Pi/index"
import { FieldData } from "piramis-base-components/src/components/Pi/types";
import { TagData } from "piramis-base-components/src/components/Tags/types";

import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  'mixins': [
    triggersMixin
  ]
})
export default class ExportConfigs extends Mixins(UseFields, InputSetups, CreateTriggerModalMixin) {
  export_chat_config: boolean = true

  export_triggers: Array<string> = []

  @Watch('export_triggers')
  exportWatcher(arr: Array<string>): void {
    if (arr.includes('all')) {
      (this as any).getSortedTriggerOptions().then((res: Array<{label: string, value: string, tags: Array<TagData>}>) => {
        this.export_triggers = res.filter((trigger) => trigger.value !== 'all').map((trigger) => trigger.value)
      })
    }
  }

  exportAll(arg: FieldData): FieldData {
    arg.setter = (val) => {
      arg.model[arg.key] = val
    }
    return arg
  }

  exportSettings(): void {
    const params = {
      chat_id: this.$store.state.chatState.chat.chat_id,
      triggers: this.export_triggers.length > 0 ? this.export_triggers : null
    }

    if (!this.export_chat_config) {
      Api.exportChatTriggers('tg', params)
    } else {
      Api.exportChatConfig('tg', params)
    }

    this.$emit('export')
  }
}
