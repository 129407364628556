















































































import { Interval } from "../types";

import { FieldData } from "piramis-base-components/src/components/Pi/types";
import DatePickerInput from "piramis-base-components/src/components/Pi/fields/DatePickerInput/DatePickerInput.vue";
import TimePickerInput from "piramis-base-components/src/components/Pi/fields/TimePickerInput/TimePickerInput.vue"

import Vue from 'vue'
import { Component, VModel } from "vue-property-decorator";
import { cloneDeep } from 'lodash'

@Component({
  components: {
    DatePickerInput,
    TimePickerInput
  },
  data() {
    return {
      cloneDeep
    }
  }
})
export default class SetInterval extends Vue {
  @VModel() interval!: Interval

  get modelCopy() {
    return cloneDeep(this.interval)
  }

  setPeriod() {
    this.$emit('setInterval', this.modelCopy)
  }

  inputSetup(args: FieldData): FieldData {
    args.setter = (value) => {
      args.model[args.key] = value
    }
    return args
  }
}
