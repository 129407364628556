











































































































































































import VuSendMessageExtra from '@/components/vu-components/VuSendMessageExtra.vue'
import Api from '../../includes/logic/Api'
import ChatBackups from '@/components/ChatBackups.vue'
import ExportConfig from '../../components/chat/ExportConfigs.vue'
import ImportConfig from '../../components/chat/ImportConfigs.vue'
import TransferLicense from '../../components/chat/TransferLicense.vue'
import RemoveForPeriod from './removeForPeriod/RemoveForPeriod.vue'
import PageTitle from '@/components/PageTitle.vue'
import ChatActions from '@/components/chat/ChatActions.vue'

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'

import { Component, Ref } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    'vu-send-message-extra': VuSendMessageExtra,
    ChatBackups,
    ExportConfig,
    ImportConfig,
    TransferLicense,
    RemoveForPeriod,
    Icon,
    PageTitle,
    ChatActions
  }
})
export default class Extra extends Vue {
  @Ref('export-chat-configs') exportConfig!: ExportConfig

  popupActive = false

  exportSettings = false

  transferLicencePopup = false

  exportSettingsHandler(): void {
    if (this.$store.state.triggersState.frontendTriggers && this.$store.state.triggersState.frontendTriggers.length) {
      this.exportSettings = true
    } else {
      this.exportConfig.export_chat_config = true
      this.exportConfig.exportSettings()
    }
  }

  get importChatConfigUrl() {
    return Api.getMethodUrl('tg', 'importchatconfig')
  }

  created(): void {
    if (!this.$store.state.triggersState.frontendTriggers) {
      this.$store.dispatch('updateTriggers')
    }
  }
}
