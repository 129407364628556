













































import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";
import { Interval } from "../types";

@Component
export default class RemoveUsersRequest extends Vue {
  @Prop() interval!: Interval
  @Prop() count!: number

  createRequest() {
    this.$emit('createRequest')
  }

  stepBack() {
    this.$emit('stepBack')
  }
}
