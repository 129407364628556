




















































































import Api from '@/includes/logic/Api'
import { errorNotification } from '@/includes/NotificationService'

import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import Tags from "piramis-base-components/src/components/Tags/Tags.vue";
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep, isEmpty } from 'lodash'

@Component({
  components: {
    Tags
  }
})
export default class TransferLicense extends Mixins(UseFields) {
  target_id = ''

  isMounted = false

  showAlert = false

  chatsTariffs: Array<number> = []

  currentTariff: number | null = null

  tariffs: Array<{key: string, img: string, color: string}> = []

  loading = false

  @Watch('target_id')
  targetWatcher(id: number): void {
    Api.getChatConfig('tg', { chat_id: id }).then((res: any) => {
      if (res.data.chat.license_timestamp && res.data.chat.license_type !== this.$store.state.chatState.chat.license_type) {
        this.showAlert = true
        this.chatsTariffs.push(this.$store.state.chatState.chat.license_type)
        this.chatsTariffs.push(res.data.chat.license_type)
        this.currentTariff = res.data.chat.license_type
      } else {
        this.showAlert = false
        this.chatsTariffs = []
        this.currentTariff = null
      }
    })
  }

  modelSetter(args: FieldData): FieldData {
    args.setter = (value: any) => {
      args.model[args.key] = value
    }
    return args
  }

  async getChatOptions(): Promise<Array<SelectOptionData>> {
    const chats = cloneDeep(this.$store.state.chatState.chatsInfo.chats).filter((chatInfo: any) => chatInfo.chat_id !== this.$store.state.chatState.chat.chat_id)
    return chats.map((chatInfo: any) => {
      return {
        label: chatInfo.group_title,
        value: chatInfo.chat_id,
        image: {
          src: chatInfo.group_photo
        }
      }
    })
  }

  transferLicense(): void {
    this.loading = true
    Api.transferLicense('tg', {
      chat_id: this.$store.state.chatState.chat.chat_id,
      target_id: this.target_id,
      license_type: this.currentTariff
    })
      .then(() => {
        this.$emit('transfer')
        this.$router.push({
          name: 'dashboard',
          params: { [EntityTypes.CHAT_ID]: this.target_id }
        })
      })
      .catch(errorNotification)
      .finally(() => {
        this.loading = false
      })
  }

  async mounted(): Promise<void> {
    this.tariffs = Object.entries(this.$store.state.themeConfig.config.tariffs).map(([ key, tariff ]) => {
      return { key, img: (tariff as any).img, color: (tariff as any).color }
    })

    if (isEmpty(this.$store.state.chatState.chatsInfo)) {
      try {
        await this.$store.dispatch('requestChatsInfo')
      } catch (e: any) {
        errorNotification(e)
        this.$emit('transfer')
      } finally {
        this.isMounted = true
      }
    } else {
      this.isMounted = true
    }
  }
}
